import { RoutesNames } from '@/routes/RoutesName';

import { TBlock } from './components/Item/ui/types';

export const dataItems: TBlock[] = [
  {
    name: 'Інформація',
    links: [
      {
        name: 'Про нас',
        link: RoutesNames.About,
      },
      {
        name: 'Контакти/Магазини',
        link: RoutesNames.Contacts,
      },
      // {
      //   name: 'Співпраця',
      //   link: '/franchise',
      // },
    ],
  },
  // {
  //   name: 'Підтримка',
  //   links: [
  //     {
  //       name: 'Доставка та оплата',
  //       link: '/store-locator',
  //     },
  //     {
  //       name: `Зв'язатися з нами`,
  //       link: '/franchise',
  //     },
  //   ],
  // },
];
