import { Typography } from '@mui/material';

export const About = () => {
  return (
    <div className='ContentWrapper ContentWrapper-Vertical'>
      <Typography variant='subtitle2' gutterBottom>
        Про нас
      </Typography>
      <Typography variant='body2' gutterBottom>
        Danceex - це магазин танцювального одягу, взуття та аксесуарів: стильні
        рішення для бальних танців, стилю high heels та інших напрямків.
      </Typography>
      <Typography variant='body2' gutterBottom>
        Сучасний ринок танцювального одягу стрімко розвивається, і наша компанія
        пропонує повний асортимент одягу, взуття та аксесуарів для танців,
        орієнтуючись на бальні танці, хілс та інші сучасні напрямки. Ми створили
        цей магазин, щоб задовольнити всі потреби танцюристів і допомогти їм
        знайти ідеальні речі для тренувань, виступів та конкурсів.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Танцювальний одяг для бальних танців
      </Typography>
      <Typography variant='body2' gutterBottom>
        Спортивно-бальні танці - це грація, стиль і класика, які потребують
        відповідного одягу. У нашому магазині представлені різноманітні сукні
        для бальних танців, бейсики, комплекти, боді, сорочки, штани, спідниці
        та костюми, спеціально розроблені для забезпечення свободи руху та
        збереження елегантного вигляду на паркеті. Весь танцювальний одяг
        виготовлений із високоякісних тканин, що відмінно тримають форму, швидко
        сохнуть і витримують часте прання.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Танцювальне взуття для бальників
      </Typography>
      <Typography variant='body2' gutterBottom>
        Стиль heels набуває все більшої популярності завдяки його сексуальності,
        енергії та чуттєвості. У нашому асортименті є все необхідне для танців у
        стилі хай хілс: боді, топи, колготи, шорти, а також спеціальні туфлі на
        високих підборах. Танцювальне взуття для heels виготовлене із
        зносостійких матеріалів, щоб витримувати інтенсивні тренування,
        забезпечуючи при цьому стабільність і комфорт. В нашому асортименті ви
        можете знайти підбори від 6 до 11 сантиметрів з різними варіантами
        підошви та матеріалів. А також, під кожну пару допоможемо підібрати
        накаблучники.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Аксесуари для танцю
      </Typography>
      <Typography variant='body2' gutterBottom>
        Щоб завершити образ і підкреслити індивідуальність, ми пропонуємо
        широкий вибір аксесуарів для танців: прикраси, наколінники, захисні
        насадки на каблуки, щіточки для взуття та багато іншого. Наші аксесуари
        додадуть образу витонченості та стануть практичним доповненням до
        тренувань або виступів.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Одяг для сучасних танців та взуття
      </Typography>
      <Typography variant='body2' gutterBottom>
        Прихильники сучасних напрямків можуть зазирнути в світ Danceex і також
        знайти для себе багато цікавого. Снікери, балетки, штани, футболки,
        накидки та багато інших товарів в наших магазинах, та на сайті компанії.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Дитячий одяг для танців
      </Typography>
      <Typography variant='body2' gutterBottom>
        Кожна дитина для своїх батьків найгарніша. Danceex творить дива і може
        перевтілити ваших маленьких янголів в справжніх принцес. Переходьте в
        категорію «дитячі сукні» і переконайтесь в цьому самостійно.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Походження товарів та власне виробництво
      </Typography>
      <Typography variant='body2' gutterBottom>
        Походження товарів та власне виробництво В магазинах Danceex
        представлені товари провідних виробників України, імпортні товари, а
        також товари власного виробництва. Торгова марка «Danceex»
        спеціалізується на взутті для high heels та має свою лінію танцювального
        одягу.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Ціни
      </Typography>
      <Typography variant='body2' gutterBottom>
        Завдяки широкому асортименту виробників, у нас ви можете підібрати товар
        під будь-який бюджет. Наші консультанти допоможуть відповісти на ваші
        запитання і зробити оптимальний вибір.
      </Typography>

      <Typography variant='subtitle2' gutterBottom>
        Заключення
      </Typography>
      <Typography variant='body2' gutterBottom>
        Наша компанія — це ідеальне місце для танцюристів, які шукають стильний
        і якісний одяг, взуття та аксесуари для танців. Ми прагнемо допомогти
        вам почуватися комфортно і впевнено на паркеті. Замовляйте танцювальне
        взуття, одяг і аксесуари у нас і зробіть свої танцювальні виступи ще
        більш яскравими!
      </Typography>
    </div>
  );
};
