import { Bounce, toast } from 'react-toastify';

import { TDanceexOptions } from '@/fsd/shared/types/scope/TypeProduct';

export function showNotification(
  response: any,
  objReq: { id: any; danceexOptions: TDanceexOptions }
) {
  let msg = objReq.id;
  if (objReq.danceexOptions.color) {
    msg += ' ' + objReq.danceexOptions.color;
  }

  if (response?.update_status) {
    msg += ' збережено на сервері';
  } else {
    msg += ' не оновлено. Зверніться до розробника';
  }

  notify(msg);
}

function notify(msg: string) {
  toast.info(msg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
    transition: Bounce,
  });
}
